import {
  Flex,
  IconButton,
  useColorMode,
  useMediaQuery,
  VStack,
} from "@chakra-ui/react";
import {
  FaSun,
  FaMoon,
  FaGithub,
  FaEnvelope,
  FaWhatsapp,
  FaDownload,
} from "react-icons/fa";
import Footer from "./components/Footer";
import Header from "./components/Header";
import Profile from "./components/Profile";

function App() {
  const { colorMode, toggleColorMode } = useColorMode();
  const [isNotSmallScreen] = useMediaQuery("(min-width: 768px)");
  const isDark = colorMode === "dark";

  return (
    <VStack p={5}>
      {isNotSmallScreen ? (
        <Flex w={"100%"} justify={"center"}>
          <IconButton
            aria-label="toggle-icon"
            icon={isDark ? <FaSun /> : <FaMoon />}
            onClick={toggleColorMode}
            isRound
          />
        </Flex>
      ) : null}
      <Header />
      <Flex>
        <IconButton
          aria-label="git-icon"
          icon={<FaGithub />}
          onClick={() => window.open("https://github.com/fahrizalm14")}
        />
        <IconButton
          ml={2}
          aria-label="google-icon"
          icon={<FaEnvelope />}
          onClick={() => window.open("mailto:fahrizalm14@gmail.com")}
        />
        <IconButton
          ml={2}
          aria-label="whatsapp-icon"
          icon={<FaWhatsapp />}
          onClick={() =>
            window.open("https://api.whatsapp.com/send?phone=62816257921")
          }
        />
        <IconButton
          ml={2}
          aria-label="download-icon"
          icon={<FaDownload />}
          onClick={() =>
            window.open(
              "https://drive.google.com/file/d/1PvfW_8JNz6vkvMJ73st8b4rpH1u26m0f/view"
            )
          }
        />
      </Flex>
      <Profile />
      <Footer />
    </VStack>
  );
}

export default App;
